.label-pill {
    display: inline-block;
    padding: 0 7px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border: 1px solid transparent;
    border-radius: 24px;
    margin-right: 2px;
    vertical-align: text-bottom;
}
