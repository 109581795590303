.card-list {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.repositories .card-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.issues .card-list {
    grid-template-columns: 1fr 1fr;
}

.card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2px;
    background-color: #fff;
    border: 1px solid #d1d5da;
    border-radius: 3px;
    padding: 16px;
    align-content: stretch;
}

.title, .desc {
    grid-column: 1 / 3;
}

.left {
    grid-column: 1 / 2;
}

.right {
    grid-column: 2 / 3;
    justify-self: right;
}

.desc {
    font-size: 14px;
    height: 2.4rem;
    overflow: hidden;
    margin-bottom: 10px;
}

.issues .desc {
    font-size: 16px;
}

.octicon {
    margin-right: 8px;
}

.left .stat {
    padding-right: 10px;
}

.stat .octicon {
    margin-right: 3px;
}

.right .stat {
    padding-left: 10px;
}

.indicator {
    float: right;
    color: #828282;
    margin-top: -4px;
    height: 28px;
}

.card-list.placeholder {
    margin-top: 16px;
}

.name-placeholder {
    width: 150px;
    height: 23px;
    animation: name-pulse 1s infinite ease-in-out;
    margin-bottom: 10px;
}

.text-placeholder {
    max-width: 600px;
    height: 20px;
    animation: text-pulse 1s infinite ease-in-out;
    margin-bottom: 10px;
}

@keyframes name-pulse {
    0% {
        background-color: rgba(3, 102, 214, 0.1);
    }
    50% {
        background-color: rgba(3, 102, 214, 0.3);
    }
    100% {
        background-color: rgba(3, 102, 214, 0.1);
    }
}

@keyframes text-pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}

.not-ok {
    color: rgba(247, 7, 7, 0.7)
}

nav .active {
    font-weight: bold;
}

.status-expected,
.status-success {
    color: rgb(26, 127, 55);
}

.status-error,
.status-failure {
    color: rgb(207, 34, 46)
}

.status-pending {
    color: rgb(154, 103, 0)
}
