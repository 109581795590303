.login-wrapper {
    text-align: center;
    margin-top: 100px;
}

.login-wrapper a {
    color: #fff;
    font-size: 18px;
    background-color: #333;
    padding: 8px 1px 8px 6px;
    border-radius: 5px;
}


.login-wrapper a:hover {
    text-decoration: none;
    color: #cfcfcf;
}
